import trailmatchImage from './trailmatch.png'; // Replace with your image file
import BruinBitesImage from './BruinBites.png'
import trailMatchCardImage from './trailmatch_cards.png'
import trailMatchMapImage from './TRAILMATCH_MAPS.png'
import DashboardImage from './Dashboard.png'
import ScImage from './SC_5ft_Elevation.png'
import CellTowersImage from './Cell_Towers_LA.png'
import DroneImagery from './handheld_spectrometer_points.png'
import Altum from './altum.png'
import profile_picture from './profile_picture.jpeg'
import bruin_bus_stop_locator_terminal from './bruin_bus_stop_locator_terminal.png'
import bruin_bus_stop_locator_final_route_output from './bruin_bus_stop_locator_final_route_output.png'
import bruin_bus_stop_locator_closest_facility_1 from './bruin_bus_stop_locator_closest_facility_1.png'
import bruin_bus_stop_locator_closest_facility_2 from './bruin_bus_stop_locator_closest_facility_2.png'
import geoexplorer_base from './geoexplorer_base.png'
// import geoexplorer_modis from './geoexplorer_modis.png'
import vernal_swale from './vernal_swale.jpeg'
import HaggleHaul from './HaggleHaul.png'
import geoexplorer_2 from './geoexplorer_not_base.png'
// import bruin_bites_profile_section from './bruin_bites_profile_section.png'
// import bruin_bites_reviews_page from './bruin_bites_reviews_page.png'
// import bruin_bites_embedded_menu from './bruin_bites_embedded_menu.png'
import AddTrip from './Add Trip.png'
import ArchivedTripsMobile from './Archived Trips Mobile.webp'
import ArchivedTrips from './Archived Trips.webp'
import PleaseLogIn from './Please Log In.webp'
import StatusInterface from './UCLA DMA Printlab.png'
import YourCustomPlaylist from './Spotify Dashboard Images/Your Custom Playlist.png'
import RecommendedTracks from './Spotify Dashboard Images/Recommended Tracks for You.png'
import YourTopTenArtists from './Spotify Dashboard Images/Your Top Ten Artists.png'
import YourListeningTrends from './Spotify Dashboard Images/Your Listening Trends.png'
import YourTopTracks from './Spotify Dashboard Images/Your Top Tracks.png'
import YourListeningPatterns from './Spotify Dashboard Images/Your Listening Patterns.png'

const images = {
  trailmatchImage,
  trailMatchCardImage,
  BruinBitesImage,
  trailMatchMapImage,
  DashboardImage,
  ScImage,
  CellTowersImage,
  DroneImagery,
  Altum,
  profile_picture,
  bruin_bus_stop_locator_terminal,
  bruin_bus_stop_locator_final_route_output,
  bruin_bus_stop_locator_closest_facility_1,
  bruin_bus_stop_locator_closest_facility_2,
  geoexplorer_base,
  // geoexplorer_modis,
  vernal_swale,
  HaggleHaul,
  geoexplorer_2,
  // bruin_bites_profile_section,
  // bruin_bites_reviews_page,
  // bruin_bites_embedded_menu,
  AddTrip,
  StatusInterface,
  
  YourCustomPlaylist,
  RecommendedTracks,
  YourTopTenArtists,
  YourListeningTrends,
  YourTopTracks,
  YourListeningPatterns
};

export default images;
